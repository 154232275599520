import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import userPlaceholder from '../../../../../assets/images/userPlaceholder.png'
import { MinusIcon, PlusIcon, TrashIcon, ArrowUturnLeftIcon } from '@heroicons/react/24/outline'
import HeadlessUIModalComponent from '../../../../shared-components/modal/HeadlessUIModal'
import MultiselectListbox from '../../../../shared-components/ListboxMultiselect/MultiselectListbox'
import { connect } from 'react-redux'
import { IMAGE_BASE_URL, defaultClassList } from '../../../../../commonServices/commonDataService'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { quillModules } from '../../../../../commonServices/quillModules'
import { classApiService, imageFileServiceApi, instructorServiceApi } from '../../../../../commonServices/apiService'
import { toast } from 'react-toastify'
import axios from 'axios'
import { INSTRUCTOR_COULDNT_CREATED, INSTRUCTOR_COULDNT_DELETED, INSTRUCTOR_CREATED_SUCCESSFULLY, INSTRUCTOR_DELETED_SUCCESSFULLY } from '../../../../../commonServices/messageConstants'
import { Tab } from '@headlessui/react'
const InstructorsList = (props) => {
    const navigate = useNavigate()
    const [allInstructors, setAllInstructors] = useState([])
    const [allClassNames, setAllClassNames] = useState([])

    const navigateToPage = (instructor) => {
        navigate(`/instructorDetails/${instructor.id}`, { state: { instructor } })
    }

    const [allCountries, setAllCountries] = useState([])
    const [curCountry, setCurCountry] = useState('IN')

    const [indexId, setCount] = useState(0);

    useEffect(() => {

        getInstructorList();
        getAllClasses();
        if (addInstructorShifts.length == 0) {
            handleAddRow()
        }
        // console.log(addInstructorWorkingDays)
        axios.get('https://gist.githubusercontent.com/anubhavshrimal/75f6183458db8c453306f93521e93d37/raw/f77e7598a8503f1f70528ae1cbf9f66755698a16/CountryCodes.json')
            .then((res) => {
                // console.log(res.data);
                setAllCountries(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])


    const getInstructorList = () => {
        instructorServiceApi.getAllInstructors()
            .then(responseData => {
                // console.log(responseData);
                if (responseData.data.status === true) {
                    setAllInstructors(responseData.data.data);
                }
            })
            .catch(err => {
                console.error(err)
            })
    }

    const getAllClasses = () => {
        classApiService.getAllClassNames()
            .then(responseData => {
                // console.log(responseData);
                if (responseData.data.status === true) {
                    setAllClassNames(responseData.data.data);
                }
            })
            .catch(err => {
                console.error(err)
            })
    }



    const [showAddInstructorModal, setShowAddInstructorModal] = useState(false)
    const [addInstructorName, setAddInstructorName] = useState('')
    const [addInstructorEmail, setAddInstructorEmail] = useState('')
    const [addInstructorContact, setAddInstructorContact] = useState('')
    const [addInstructorAddress, setAddInstructorAddress] = useState('')
    const [addInstructorDescription, setAddInstructorDescription] = useState('')
    const [addInstructorSkills, setAddInstructorSkills] = useState('')
    const [addInstructorImage, setAddInstructorImage] = useState(null)
    const [addInstructorServices, setAddInstructorServices] = useState([])
    const [addInstructorClasses, setAddInstructorClasses] = useState([])
    const [addInstructorWorkingDays, setAddInstructorWorkingDays] = useState({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
    })
    const [addInstructorShifts, setAddInstructorShifts] = useState([
        { id: 0, startTime: '', endTime: '' },
    ])
    const [addInstructorShiftsMonday, setaddInstructorShiftsMonday] = useState([
        { id: 0, startTime: '', endTime: '' },
    ])
    const [addInstructorShiftsTuesday, setaddInstructorShiftsTuesday] = useState([
        { id: 0, startTime: '', endTime: '' },
    ])
    const [addInstructorShiftsWednesday, setaddInstructorShiftsWednesday] = useState([
        { id: 0, startTime: '', endTime: '' },
    ])
    const [addInstructorShiftsThursday, setaddInstructorShiftsThursday] = useState([
        { id: 0, startTime: '', endTime: '' },
    ])
    const [addInstructorShiftsFriday, setaddInstructorShiftsFriday] = useState([
        { id: 0, startTime: '', endTime: '' },
    ])
    const [addInstructorShiftsSaturday, setaddInstructorShiftsSaturday] = useState([
        { id: 0, startTime: '', endTime: '' },
    ])
    const [addInstructorShiftsSunday, setaddInstructorShiftsSunday] = useState([
        { id: 0, startTime: '', endTime: '' },
    ])

    // Deletion states
    const [showDeleteInstructorModal, setShowDeleteInstructorModal] = useState(false)
    const [deleteInstructorId, setDeleteInstructorId] = useState(null)
    const instructorsList = (
        <div className="mt-6 flex flex-col">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden border border-gray-50 md:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-50">
                            <thead className="bg-gray-300">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-4 py-3.5 text-left text-sm font-normal text-gray-700"
                                    >
                                        Instructor
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-12 py-3.5 text-left text-sm font-normal text-gray-700"
                                    >
                                        Contact
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-4 py-3.5 text-center text-sm font-normal text-gray-700"
                                    >
                                        Status
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-4 py-3.5 text-center text-sm font-normal text-gray-700"
                                    >
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-50 bg-gray-300/50">
                                {allInstructors.length === 0 && (
                                    <tr>
                                        <td colSpan='4' className='text-center py-4 text-gray-700'>No Instructors to display!</td>
                                    </tr>
                                )}
                                {allInstructors.map((instructor) => (
                                    <tr key={instructor.id}>
                                        <td className="whitespace-nowrap px-4 py-4">
                                            <div className="flex items-center gap-2">
                                                <div className='w-10 h-10'>
                                                    <img
                                                        className="h-10 w-10 rounded-full"
                                                        src={instructor.profileImage ? IMAGE_BASE_URL + instructor.profileImage : userPlaceholder}
                                                        alt={instructor.name}
                                                    />
                                                </div>
                                                <div className="block text-left">
                                                    <div className="text-sm font-medium text-gray-900">{instructor.name}</div>
                                                    <div className="text-sm text-gray-600" dangerouslySetInnerHTML={{ __html: `${instructor.description.substring(0, 50)}${instructor.description.length <= 50 ? '' : '...'}` }} />
                                                </div>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap text-left px-12 py-4">
                                            <div className="text-sm text-gray-900">{instructor.email}</div>
                                            <div className="text-sm text-gray-600">{instructor.countryCode} {instructor.contactNumber}</div>
                                        </td>
                                        <td className="whitespace-nowrap px-4 py-4 text-center text-sm font-medium">
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${instructor.status ? 'bg-green-200 text-green-900' : 'bg-red-200 text-red-900'}`}>
                                                {instructor.status ? 'Active' : 'Inactive'}
                                            </span>
                                        </td>
                                        <td className="whitespace-nowrap px-4 py-4 text-center text-sm font-medium flex gap-1">
                                            <button onClick={() => navigateToPage(instructor)} title='Edit/View Admin' className="rounded-xl bg-indigo-200 mx-auto px-4 py-2 text-sm font-semibold text-indigo-900 shadow-sm hover:bg-indigo-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
                                                Edit / View
                                            </button>
                                            <button onClick={(e) => {
                                                e.preventDefault();
                                                setDeleteInstructorId(instructor.id);
                                                setShowDeleteInstructorModal(true);
                                            }}
                                                title='Delete Admin' className="rounded-xl bg-red-200 mx-auto p-2 text-sm font-semibold text-red-900 shadow-sm hover:bg-red-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
                                                <TrashIcon className='w-5 h-5' />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

    const handleUploadInstructorImage = (e) => {
        if (e.target.files[0]) {
            let file = e.target.files[0];
            let fdata = new FormData();
            fdata.append('image', file);
            fdata.append('prevImageName', addInstructorImage);
            imageFileServiceApi.uploadImage(fdata)
                .then((res) => {
                    // console.log(res);
                    setAddInstructorImage(res.data.data.filename);
                    toast.success('Image uploaded successfully');
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }
    const [selectedDays, setSelectedDays] = useState('monday')
    //selected working
    const getDayName = (e) => {
        setSelectedDays('')
        setSelectedDays(e);
    }
    const resteHandlerRecord = (value) => {
        if (selectedDays == 'monday') {
            addInstructorShiftsMonday.forEach(element => {
                if (element.id == value.id) {
                    element.startTime = ''
                    element.endTime = ''
                }
            });
            setaddInstructorShiftsMonday([...addInstructorShiftsMonday]);
        }
        if (selectedDays == 'tuesday') {
            addInstructorShiftsTuesday.forEach(element => {
                if (element.id == value.id) {
                    element.startTime = ''
                    element.endTime = ''
                }
            });
            setaddInstructorShiftsTuesday([...addInstructorShiftsMonday]);
        }
        if (selectedDays == 'wednesday') {
            addInstructorShiftsWednesday.forEach(element => {
                if (element.id == value.id) {
                    element.startTime = ''
                    element.endTime = ''
                }
            });
            setaddInstructorShiftsWednesday([...addInstructorShiftsWednesday]);
        }
        if (selectedDays == 'thursday') {
            addInstructorShiftsThursday.forEach(element => {
                if (element.id == value.id) {
                    element.startTime = ''
                    element.endTime = ''
                }
            });
            setaddInstructorShiftsThursday([...addInstructorShiftsThursday]);
        }
        if (selectedDays == 'friday') {
            addInstructorShiftsFriday.forEach(element => {
                if (element.id == value.id) {
                    element.startTime = ''
                    element.endTime = ''
                }
            });
            setaddInstructorShiftsFriday([...addInstructorShiftsFriday]);
        }
        if (selectedDays == 'sunday') {
            addInstructorShiftsSunday.forEach(element => {
                if (element.id == value.id) {
                    element.startTime = ''
                    element.endTime = ''
                }
            });
            setaddInstructorShiftsSunday([...addInstructorShiftsSunday]);
        }
        if (selectedDays == 'saturday') {
            addInstructorShiftsSaturday.forEach(element => {
                if (element.id == value.id) {
                    element.startTime = ''
                    element.endTime = ''
                }
            });
            setaddInstructorShiftsSaturday([...addInstructorShiftsSaturday]);
        }
    }


    const handleAddInstructor = (e) => {


        var shiftTiming = {
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
            sunday: ''
        }
        if (addInstructorShiftsMonday.length > 0) {
            shiftTiming.monday = addInstructorShiftsMonday.filter(item => item.startTime !== '' || item.endTime !== '')
            if (shiftTiming?.monday?.length > 0) {
                addInstructorWorkingDays.monday = true
            } else {
                addInstructorWorkingDays.monday = false
            }
        }
        if (addInstructorShiftsTuesday.length > 0) {
            shiftTiming.tuesday = addInstructorShiftsTuesday.filter(item => item.startTime !== '' || item.endTime !== '')

            if (shiftTiming?.tuesday?.length > 0) {
                addInstructorWorkingDays.tuesday = true
            } else {
                addInstructorWorkingDays.tuesday = false
            }
        }
        if (addInstructorShiftsWednesday.length > 0) {
            shiftTiming.wednesday = addInstructorShiftsWednesday.filter(item => item.startTime !== '' || item.endTime !== '')
            if (shiftTiming?.wednesday?.length > 0) {
                addInstructorWorkingDays.wednesday = true
            } else {
                addInstructorWorkingDays.wednesday = false
            }
        }
        if (addInstructorShiftsThursday.length > 0) {
            shiftTiming.thursday = addInstructorShiftsThursday.filter(item => item.startTime !== '' || item.endTime !== '')
            if (shiftTiming?.thursday?.length > 0) {
                addInstructorWorkingDays.thursday = true
            } else {
                addInstructorWorkingDays.thursday = false
            }
        }
        if (addInstructorShiftsFriday.length > 0) {
            shiftTiming.friday = addInstructorShiftsFriday.filter(item => item.startTime !== '' || item.endTime !== '')
            if (shiftTiming?.friday?.length > 0) {
                addInstructorWorkingDays.friday = true
            } else {
                addInstructorWorkingDays.friday = false
            }
        }
        if (addInstructorShiftsSaturday.length > 0) {
            shiftTiming.saturday = addInstructorShiftsSaturday.filter(item => item.startTime !== '' || item.endTime !== '')
            if (shiftTiming?.saturday?.length > 0) {
                addInstructorWorkingDays.saturday = true
            } else {
                addInstructorWorkingDays.saturday = false
            }
        }
        if (addInstructorShiftsSunday.length > 0) {
            shiftTiming.sunday = addInstructorShiftsSunday.filter(item => item.startTime !== '' || item.endTime !== '')
            if (shiftTiming?.sunday?.length > 0) {
                addInstructorWorkingDays.sunday = true
            } else {
                addInstructorWorkingDays.sunday = false
            }
        }
        if (addInstructorName.trim() === '' || addInstructorEmail.trim() === '' || addInstructorContact.trim() === '' || addInstructorAddress.trim() === '' || addInstructorDescription.trim() === '' || addInstructorSkills.trim() === '') {
            toast.error('All fields are required');
            return;
        }

        e.preventDefault();
        let body = {
            name: addInstructorName,
            email: addInstructorEmail,
            contactNumber: parseInt(addInstructorContact),
            countryCode: allCountries.find((country) => country.code === curCountry).dial_code,
            address: addInstructorAddress,
            description: addInstructorDescription,
            skills: addInstructorSkills.split(',').map((skill) => skill.trim()),
            associatedServices: addInstructorServices.map((service) => ({ id: service.id, treatmentServiceName: service.treatmentServiceName })),
            associatedClasses: addInstructorClasses,
            workDays: addInstructorWorkingDays,
            shiftTiming: shiftTiming,
            profileImage: addInstructorImage,
            status: 0
        }
        // console.log(body);
        instructorServiceApi.createInstructor(body)
            .then(responseData => {
                if (responseData.data.status === true) {
                    toast.success(INSTRUCTOR_CREATED_SUCCESSFULLY);
                    getInstructorList();
                    setAddInstructorName('');
                    setAddInstructorEmail('');
                    setAddInstructorContact('');
                    setAddInstructorAddress('');
                    setAddInstructorDescription('');
                    setAddInstructorSkills('');
                    setAddInstructorServices([]);
                    setAddInstructorClasses([]);
                    setAddInstructorWorkingDays({
                        monday: false,
                        tuesday: false,
                        wednesday: false,
                        thursday: false,
                        friday: false,
                        saturday: false,
                        sunday: false
                    });
                    // setAddInstructorShifts([{ id: 0, startTime: '', endTime: '' }]);
                    setAddInstructorImage(null);
                    setaddInstructorShiftsMonday([{ id: 0, startTime: '', endTime: '' }])
                    setaddInstructorShiftsTuesday([{ id: 0, startTime: '', endTime: '' }])
                    setaddInstructorShiftsWednesday([{ id: 0, startTime: '', endTime: '' }])
                    setaddInstructorShiftsThursday([{ id: 0, startTime: '', endTime: '' }])
                    setaddInstructorShiftsFriday([{ id: 0, startTime: '', endTime: '' }])
                    setaddInstructorShiftsSaturday([{ id: 0, startTime: '', endTime: '' }])
                    setaddInstructorShiftsSunday([{ id: 0, startTime: '', endTime: '' }])
                } else {
                    toast.error(INSTRUCTOR_COULDNT_CREATED);
                }
            })
            .catch(err => {
                console.error(err)
                toast.error(INSTRUCTOR_COULDNT_CREATED);
            })
        setShowAddInstructorModal(false);
    }

    const addInstructorModalBody = (
        <div className='mt-4'>
            <div className="flex items-center gap-2">
                <div className='mb-2 w-1/2'>
                    <label htmlFor='instructorName' className='block text-sm font-medium text-gray-700'>Instructor Name</label>
                    <input
                        type='text'
                        name='instructorName'
                        id='instructorName'
                        placeholder='John Doe'
                        required
                        value={addInstructorName}
                        onChange={(e) => setAddInstructorName(e.target.value)}
                        className='mt-[2px] block w-full px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                    />
                </div>
                <div className='mb-2 w-1/2'>
                    <label htmlFor='instructorContact' className='block text-sm font-medium text-gray-700'>Contact</label>
                    <div className='flex items-center mt-[2px]'>
                        <select
                            name="countryCode"
                            id="countryCode"
                            className="shadow-sm text-sm rounded-l-md py-1.5 border-2 border-gray-300 focus:border-indigo-600"
                            value={curCountry}
                            onChange={(e) => { setCurCountry(e.target.value) }}
                        >
                            {allCountries.map(country => <option
                                key={country.code}
                                value={country.code}
                            >
                                {country.code} ({country.dial_code})
                            </option>
                            )}
                        </select>
                        <input
                            type='text'
                            name='instructorContact'
                            id='instructorContact'
                            placeholder='9876543210'
                            required
                            value={addInstructorContact}
                            maxLength={12}
                            onChange={(e) => setAddInstructorContact(e.target.value.replace(/[^0-9]/, ''))}
                            className='block w-full px-3 py-1.5 border-2 border-gray-300 rounded-r-md shadow-sm focus:border-indigo-500 sm:text-sm focus:outline-none'
                        />
                    </div>
                </div>
            </div>
            <div className='mb-2'>
                <label htmlFor='instructorEmail' className='block text-sm font-medium text-gray-700'>Instructor Email</label>
                <input
                    type='email'
                    name='instructorEmail'
                    id='instructorEmail'
                    placeholder='johndoe@example.com'
                    required
                    value={addInstructorEmail}
                    onChange={(e) => setAddInstructorEmail(e.target.value)}
                    className='mt-[2px] block w-full px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                />
            </div>
            <div className='mb-2'>
                <label htmlFor='instructorAddress' className='block text-sm font-medium text-gray-700'>Instructor Address</label>
                <input
                    type='text'
                    name='instructorAddress'
                    id='instructorAddress'
                    placeholder='123, Street Name, City, State, Country - 123456'
                    required
                    value={addInstructorAddress}
                    onChange={(e) => setAddInstructorAddress(e.target.value)}
                    className='mt-[2px] block w-full px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                />
            </div>
            <div className="flex items-center gap-2">
                <div className='mb-2 w-1/2'>
                    <label htmlFor='instructorDescription' className='block text-sm font-medium text-gray-700'>Description</label>
                    <ReactQuill
                        theme="snow"
                        modules={quillModules}
                        value={addInstructorDescription}
                        onChange={setAddInstructorDescription}
                        placeholder='Write something about the instructor...'
                        className='mt-[2px] border-2 border-gray-300 rounded-md shadow-sm focus-within:border-indigo-600 sm:text-sm focus:outline-none'
                    />
                </div>
                <div className='mb-2 w-1/2'>
                    <label htmlFor='instructorSkills' className='block text-sm font-medium text-gray-700'>Skills (comma separated)</label>
                    <textarea
                        id='instructorSkills'
                        rows={3}
                        required
                        value={addInstructorSkills}
                        onChange={(e) => setAddInstructorSkills(e.target.value)}
                        placeholder='Skill 1, Skill 2, Skill 3...'
                        className='mt-[2px] block w-full px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                    />
                </div>
            </div>
            <div className="flex items-center gap-2">
                <div className='mb-2 w-1/2'>
                    <MultiselectListbox
                        items={props.allServices.allServicesData}
                        selectedItems={addInstructorServices}
                        setSelectedItems={setAddInstructorServices}
                        label='Select Services'
                        type='services'
                        nameVariable='treatmentServiceName'
                    />
                </div>
                <div className='mb-2 w-1/2'>
                    <MultiselectListbox
                        items={allClassNames}
                        selectedItems={addInstructorClasses}
                        setSelectedItems={setAddInstructorClasses}
                        label='Select Classes'
                        type='classes'
                        nameVariable='className'
                    />
                </div>
            </div>
            <div className="mb-2">
                <label htmlFor='days' className='block text-sm font-medium text-gray-700'>Working Days</label>
                <Tab.Group>
                    <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
                        {Object.keys(addInstructorWorkingDays).map((day) => (
                            <Tab key={day} className='select-none'
                                onClick={(e) => {
                                    getDayName(day)
                                }}>
                                <label
                                    htmlFor={`day_${day}`}
                                    className={` flex items-center gap-0.5 cursor-pointer  ${day == selectedDays ? 'bg-indigo-500 text-white' : 'bg-gray-300 text-gray-700'} px-3 py-1.5 rounded-md shadow-sm focus:outline-none`}
                                >
                                    {


                                        <PlusIcon className='w-4 h-4 inline-block' />
                                    }
                                    {day.slice(0, 3).toUpperCase()}
                                </label>

                                <input
                                    type='checkbox'
                                    name={day}
                                    id={`day_${day}`}
                                    checked={addInstructorWorkingDays[day]}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setAddInstructorWorkingDays({ ...addInstructorWorkingDays, [day]: true });
                                        } else {
                                            setAddInstructorWorkingDays({ ...addInstructorWorkingDays, [day]: false });
                                        }
                                    }
                                    }
                                    className='hidden'
                                />

                            </Tab>
                        ))}



                    </Tab.List>
                    <Tab.Panels>
                        <Tab.Panel><div className="mb-2">
                            <label htmlFor='shifts' className='block text-sm font-medium text-gray-700'>
                                Working Shifts {selectedDays}

                            </label>
                            <div className="flex flex-col items-center gap-2 mt-2">
                                {addInstructorShiftsMonday.map((shift, index) => (
                                    <div key={shift.id} className='flex gap-2 items-center'>
                                        <div className='text-sm font-bold text-gray-950 mr-12'>{`Shift ${index + 1}`}</div>
                                        <label htmlFor={`startTime_${index}`} className='block text-sm font-medium text-gray-700'>Start Time</label>
                                        <input
                                            type='time'
                                            name={`startTime_${index}`}
                                            id={`startTime_${index}`}
                                            value={shift.startTime}
                                            onChange={(e) => {
                                                const newShifts = [...addInstructorShiftsMonday];
                                                newShifts[index].startTime = e.target.value;
                                                setaddInstructorShiftsMonday(newShifts);
                                            }}
                                            className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                        />
                                        <label htmlFor={`endTime_${index}`} className='block text-sm font-medium text-gray-700'>End Time</label>
                                        <input
                                            type='time'
                                            name={`endTime_${index}`}
                                            id={`endTime_${index}`}
                                            value={shift.endTime}
                                            onChange={(e) => {
                                                const newShifts = [...addInstructorShiftsMonday];
                                                newShifts[index].endTime = e.target.value;
                                                setaddInstructorShiftsMonday(newShifts);
                                            }}
                                            className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                        />
                                        <button
                                            disabled={addInstructorShiftsMonday.length === 1}
                                            onClick={() => {
                                                const newShifts = addInstructorShiftsMonday.filter((shft) => shft.id !== shift.id);
                                                setaddInstructorShiftsMonday(newShifts);
                                            }}
                                            className='text-red-900 bg-red-200 hover:bg-red-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                        >
                                            <TrashIcon className='w-[18px] h-[18px]' />
                                        </button>
                                        <button

                                            onClick={() => {
                                                handleAddRow()
                                            }}
                                            className='text-green-900 bg-green-200 hover:bg-green-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                        >
                                            <PlusIcon className='w-[18px] h-[18px]' />
                                        </button>
                                        <button
                                            onClick={() => {
                                                resteHandlerRecord(shift)
                                            }}
                                            className='text-gray-900 bg-gray-200 hover:bg-gray-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                        >
                                            <ArrowUturnLeftIcon className='w-[18px] h-[18px]' />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div></Tab.Panel>
                        <Tab.Panel><div className="mb-2">
                            <label htmlFor='shifts' className='block text-sm font-medium text-gray-700'>
                                Working Shifts {selectedDays}

                            </label>
                            <div className="flex flex-col items-center gap-2 mt-2">
                                {addInstructorShiftsTuesday.map((shift, index) => (
                                    <div key={shift.id} className='flex gap-2 items-center'>
                                        <div className='text-sm font-bold text-gray-950 mr-12'>{`Shift ${index + 1}`}</div>
                                        <label htmlFor={`startTime_${index}`} className='block text-sm font-medium text-gray-700'>Start Time</label>
                                        <input
                                            type='time'
                                            name={`startTime_${index}`}
                                            id={`startTime_${index}`}
                                            value={shift.startTime}
                                            onChange={(e) => {
                                                const newShifts = [...addInstructorShiftsTuesday];
                                                newShifts[index].startTime = e.target.value;
                                                setaddInstructorShiftsTuesday(newShifts);
                                            }}
                                            className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                        />
                                        <label htmlFor={`endTime_${index}`} className='block text-sm font-medium text-gray-700'>End Time</label>
                                        <input
                                            type='time'
                                            name={`endTime_${index}`}
                                            id={`endTime_${index}`}
                                            value={shift.endTime}
                                            onChange={(e) => {
                                                const newShifts = [...addInstructorShiftsTuesday];
                                                newShifts[index].endTime = e.target.value;
                                                setaddInstructorShiftsTuesday(newShifts);
                                            }}
                                            className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                        />
                                        <button
                                            disabled={addInstructorShiftsTuesday.length === 1}
                                            onClick={() => {
                                                const newShifts = addInstructorShiftsTuesday.filter((shft) => shft.id !== shift.id);
                                                setaddInstructorShiftsTuesday(newShifts);
                                            }}
                                            className='text-red-900 bg-red-200 hover:bg-red-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                        >
                                            <TrashIcon className='w-[18px] h-[18px]' />
                                        </button>
                                        <button

                                            onClick={() => {
                                                handleAddRow()
                                            }}
                                            className='text-green-900 bg-green-200 hover:bg-green-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                        >
                                            <PlusIcon className='w-[18px] h-[18px]' />
                                        </button>
                                        <button
                                            onClick={() => {
                                                resteHandlerRecord(shift)
                                            }}
                                            className='text-gray-900 bg-gray-200 hover:bg-gray-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                        >
                                            <ArrowUturnLeftIcon className='w-[18px] h-[18px]' />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div></Tab.Panel>
                        <Tab.Panel><div className="mb-2">
                            <label htmlFor='shifts' className='block text-sm font-medium text-gray-700'>
                                Working Shifts {selectedDays}

                            </label>
                            <div className="flex flex-col items-center gap-2 mt-2">
                                {addInstructorShiftsWednesday.map((shift, index) => (
                                    <div key={shift.id} className='flex gap-2 items-center'>
                                        <div className='text-sm font-bold text-gray-950 mr-12'>{`Shift ${index + 1}`}</div>
                                        <label htmlFor={`startTime_${index}`} className='block text-sm font-medium text-gray-700'>Start Time</label>
                                        <input
                                            type='time'
                                            name={`startTime_${index}`}
                                            id={`startTime_${index}`}
                                            value={shift.startTime}
                                            onChange={(e) => {
                                                const newShifts = [...addInstructorShiftsWednesday];
                                                newShifts[index].startTime = e.target.value;
                                                setaddInstructorShiftsWednesday(newShifts);
                                            }}
                                            className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                        />
                                        <label htmlFor={`endTime_${index}`} className='block text-sm font-medium text-gray-700'>End Time</label>
                                        <input
                                            type='time'
                                            name={`endTime_${index}`}
                                            id={`endTime_${index}`}
                                            value={shift.endTime}
                                            onChange={(e) => {
                                                const newShifts = [...addInstructorShiftsWednesday];
                                                newShifts[index].endTime = e.target.value;
                                                setaddInstructorShiftsWednesday(newShifts);
                                            }}
                                            className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                        />
                                        <button
                                            disabled={addInstructorShiftsWednesday.length === 1}
                                            onClick={() => {
                                                const newShifts = addInstructorShiftsWednesday.filter((shft) => shft.id !== shift.id);
                                                setaddInstructorShiftsTuesday(newShifts);
                                            }}
                                            className='text-red-900 bg-red-200 hover:bg-red-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                        >
                                            <TrashIcon className='w-[18px] h-[18px]' />
                                        </button>
                                        <button

                                            onClick={() => {
                                                handleAddRow()
                                            }}
                                            className='text-green-900 bg-green-200 hover:bg-green-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                        >
                                            <PlusIcon className='w-[18px] h-[18px]' />
                                        </button>
                                        <button
                                            onClick={() => {
                                                resteHandlerRecord(shift)
                                            }}
                                            className='text-gray-900 bg-gray-200 hover:bg-gray-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                        >
                                            <ArrowUturnLeftIcon className='w-[18px] h-[18px]' />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div></Tab.Panel>
                        <Tab.Panel><div className="mb-2">
                            <label htmlFor='shifts' className='block text-sm font-medium text-gray-700'>
                                Working Shifts {selectedDays}

                            </label>
                            <div className="flex flex-col items-center gap-2 mt-2">
                                {addInstructorShiftsThursday.map((shift, index) => (
                                    <div key={shift.id} className='flex gap-2 items-center'>
                                        <div className='text-sm font-bold text-gray-950 mr-12'>{`Shift ${index + 1}`}</div>
                                        <label htmlFor={`startTime_${index}`} className='block text-sm font-medium text-gray-700'>Start Time</label>
                                        <input
                                            type='time'
                                            name={`startTime_${index}`}
                                            id={`startTime_${index}`}
                                            value={shift.startTime}
                                            onChange={(e) => {
                                                const newShifts = [...addInstructorShiftsThursday];
                                                newShifts[index].startTime = e.target.value;
                                                setaddInstructorShiftsThursday(newShifts);
                                            }}
                                            className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                        />
                                        <label htmlFor={`endTime_${index}`} className='block text-sm font-medium text-gray-700'>End Time</label>
                                        <input
                                            type='time'
                                            name={`endTime_${index}`}
                                            id={`endTime_${index}`}
                                            value={shift.endTime}
                                            onChange={(e) => {
                                                const newShifts = [...addInstructorShiftsThursday];
                                                newShifts[index].endTime = e.target.value;
                                                setaddInstructorShiftsThursday(newShifts);
                                            }}
                                            className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                        />
                                        <button
                                            disabled={addInstructorShiftsThursday.length === 1}
                                            onClick={() => {
                                                const newShifts = addInstructorShiftsThursday.filter((shft) => shft.id !== shift.id);
                                                setaddInstructorShiftsThursday(newShifts);
                                            }}
                                            className='text-red-900 bg-red-200 hover:bg-red-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                        >
                                            <TrashIcon className='w-[18px] h-[18px]' />
                                        </button>
                                        <button

                                            onClick={() => {
                                                handleAddRow()
                                            }}
                                            className='text-green-900 bg-green-200 hover:bg-green-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                        >
                                            <PlusIcon className='w-[18px] h-[18px]' />
                                        </button>
                                        <button
                                            onClick={() => {
                                                resteHandlerRecord(shift)
                                            }}
                                            className='text-gray-900 bg-gray-200 hover:bg-gray-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                        >
                                            <ArrowUturnLeftIcon className='w-[18px] h-[18px]' />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div></Tab.Panel>
                        <Tab.Panel><div className="mb-2">
                            <label htmlFor='shifts' className='block text-sm font-medium text-gray-700'>
                                Working Shifts {selectedDays}

                            </label>
                            <div className="flex flex-col items-center gap-2 mt-2">
                                {addInstructorShiftsFriday.map((shift, index) => (
                                    <div key={shift.id} className='flex gap-2 items-center'>
                                        <div className='text-sm font-bold text-gray-950 mr-12'>{`Shift ${index + 1}`}</div>
                                        <label htmlFor={`startTime_${index}`} className='block text-sm font-medium text-gray-700'>Start Time</label>
                                        <input
                                            type='time'
                                            name={`startTime_${index}`}
                                            id={`startTime_${index}`}
                                            value={shift.startTime}
                                            onChange={(e) => {
                                                const newShifts = [...addInstructorShiftsFriday];
                                                newShifts[index].startTime = e.target.value;
                                                setaddInstructorShiftsFriday(newShifts);
                                            }}
                                            className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                        />
                                        <label htmlFor={`endTime_${index}`} className='block text-sm font-medium text-gray-700'>End Time</label>
                                        <input
                                            type='time'
                                            name={`endTime_${index}`}
                                            id={`endTime_${index}`}
                                            value={shift.endTime}
                                            onChange={(e) => {
                                                const newShifts = [...addInstructorShiftsFriday];
                                                newShifts[index].endTime = e.target.value;
                                                setaddInstructorShiftsFriday(newShifts);
                                            }}
                                            className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                        />
                                        <button
                                            disabled={addInstructorShiftsFriday.length === 1}
                                            onClick={() => {
                                                const newShifts = addInstructorShiftsFriday.filter((shft) => shft.id !== shift.id);
                                                setaddInstructorShiftsFriday(newShifts);
                                            }}
                                            className='text-red-900 bg-red-200 hover:bg-red-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                        >
                                            <TrashIcon className='w-[18px] h-[18px]' />
                                        </button>
                                        <button

                                            onClick={() => {
                                                handleAddRow()
                                            }}
                                            className='text-green-900 bg-green-200 hover:bg-green-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                        >
                                            <PlusIcon className='w-[18px] h-[18px]' />
                                        </button>
                                        <button
                                            onClick={() => {
                                                resteHandlerRecord(shift)
                                            }}
                                            className='text-gray-900 bg-gray-200 hover:bg-gray-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                        >
                                            <ArrowUturnLeftIcon className='w-[18px] h-[18px]' />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div></Tab.Panel>
                        <Tab.Panel><div className="mb-2">
                            <label htmlFor='shifts' className='block text-sm font-medium text-gray-700'>
                                Working Shifts {selectedDays}

                            </label>
                            <div className="flex flex-col items-center gap-2 mt-2">
                                {addInstructorShiftsSaturday.map((shift, index) => (
                                    <div key={shift.id} className='flex gap-2 items-center'>
                                        <div className='text-sm font-bold text-gray-950 mr-12'>{`Shift ${index + 1}`}</div>
                                        <label htmlFor={`startTime_${index}`} className='block text-sm font-medium text-gray-700'>Start Time</label>
                                        <input
                                            type='time'
                                            name={`startTime_${index}`}
                                            id={`startTime_${index}`}
                                            value={shift.startTime}
                                            onChange={(e) => {
                                                const newShifts = [...addInstructorShiftsSaturday];
                                                newShifts[index].startTime = e.target.value;
                                                setaddInstructorShiftsSaturday(newShifts);
                                            }}
                                            className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                        />
                                        <label htmlFor={`endTime_${index}`} className='block text-sm font-medium text-gray-700'>End Time</label>
                                        <input
                                            type='time'
                                            name={`endTime_${index}`}
                                            id={`endTime_${index}`}
                                            value={shift.endTime}
                                            onChange={(e) => {
                                                const newShifts = [...addInstructorShiftsSaturday];
                                                newShifts[index].endTime = e.target.value;
                                                setaddInstructorShiftsSaturday(newShifts);
                                            }}
                                            className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                        />
                                        <button
                                            disabled={addInstructorShiftsSaturday.length === 1}
                                            onClick={() => {
                                                const newShifts = addInstructorShiftsSaturday.filter((shft) => shft.id !== shift.id);
                                                setaddInstructorShiftsSaturday(newShifts);
                                            }}
                                            className='text-red-900 bg-red-200 hover:bg-red-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                        >
                                            <TrashIcon className='w-[18px] h-[18px]' />
                                        </button>
                                        <button

                                            onClick={() => {
                                                handleAddRow()
                                            }}
                                            className='text-green-900 bg-green-200 hover:bg-green-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                        >
                                            <PlusIcon className='w-[18px] h-[18px]' />
                                        </button>
                                        <button
                                            onClick={() => {
                                                resteHandlerRecord(shift)
                                            }}
                                            className='text-gray-900 bg-gray-200 hover:bg-gray-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                        >
                                            <ArrowUturnLeftIcon className='w-[18px] h-[18px]' />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div></Tab.Panel>
                        <Tab.Panel><div className="mb-2">
                            <label htmlFor='shifts' className='block text-sm font-medium text-gray-700'>
                                Working Shifts {selectedDays}

                            </label>
                            <div className="flex flex-col items-center gap-2 mt-2">
                                {addInstructorShiftsSunday.map((shift, index) => (
                                    <div key={shift.id} className='flex gap-2 items-center'>
                                        <div className='text-sm font-bold text-gray-950 mr-12'>{`Shift ${index + 1}`}</div>
                                        <label htmlFor={`startTime_${index}`} className='block text-sm font-medium text-gray-700'>Start Time</label>
                                        <input
                                            type='time'
                                            name={`startTime_${index}`}
                                            id={`startTime_${index}`}
                                            value={shift.startTime}
                                            onChange={(e) => {
                                                const newShifts = [...addInstructorShiftsSunday];
                                                newShifts[index].startTime = e.target.value;
                                                setaddInstructorShiftsSaturday(newShifts);
                                            }}
                                            className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                        />
                                        <label htmlFor={`endTime_${index}`} className='block text-sm font-medium text-gray-700'>End Time</label>
                                        <input
                                            type='time'
                                            name={`endTime_${index}`}
                                            id={`endTime_${index}`}
                                            value={shift.endTime}
                                            onChange={(e) => {
                                                const newShifts = [...addInstructorShiftsSunday];
                                                newShifts[index].endTime = e.target.value;
                                                setaddInstructorShiftsSunday(newShifts);
                                            }}
                                            className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                        />
                                        <button
                                            disabled={addInstructorShiftsSunday.length === 1}
                                            onClick={() => {
                                                const newShifts = addInstructorShiftsSunday.filter((shft) => shft.id !== shift.id);
                                                setaddInstructorShiftsSunday(newShifts);
                                            }}
                                            className='text-red-900 bg-red-200 hover:bg-red-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                        >
                                            <TrashIcon className='w-[18px] h-[18px]' />
                                        </button>
                                        <button

                                            onClick={() => {
                                                handleAddRow()
                                            }}
                                            className='text-green-900 bg-green-200 hover:bg-green-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                        >
                                            <PlusIcon className='w-[18px] h-[18px]' />
                                        </button>
                                        <button
                                            onClick={() => {
                                                resteHandlerRecord(shift)
                                            }}
                                            className='text-gray-900 bg-gray-200 hover:bg-gray-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                        >
                                            <ArrowUturnLeftIcon className='w-[18px] h-[18px]' />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div></Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>





                {/* <div className="flex items-center gap-1 mt-2">
                    {Object.keys(addInstructorWorkingDays).map((day) => (
                        <div key={day} className='select-none'>
                            <label
                                htmlFor={`day_${day}`}
                                className={` flex items-center gap-0.5 cursor-pointer ${addInstructorWorkingDays[day] ? 'bg-indigo-500 text-white' : 'bg-gray-300 text-gray-700'} px-3 py-1.5 rounded-md shadow-sm focus:outline-none`}
                            >
                                {
                                    addInstructorWorkingDays[day] ?
                                        <MinusIcon className='w-4 h-4 inline-block' />
                                        :
                                        <PlusIcon className='w-4 h-4 inline-block' />
                                }
                                {day.slice(0, 3).toUpperCase()}
                            </label>

                            <input
                                type='checkbox'
                                name={day}
                                id={`day_${day}`}
                                checked={addInstructorWorkingDays[day]}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setAddInstructorWorkingDays({ ...addInstructorWorkingDays, [day]: true });
                                    } else {
                                        setAddInstructorWorkingDays({ ...addInstructorWorkingDays, [day]: false });
                                    }
                                }
                                }
                                className='hidden'
                            />
                        </div>
                    ))}
                </div> */}
            </div>
            {/* <div className="mb-2">
                <label htmlFor='shifts' className='block text-sm font-medium text-gray-700'>
                    Working Shifts

                </label>
                <div className="flex flex-col items-center gap-2 mt-2">
                    {addInstructorShifts.map((shift, index) => (
                        <div key={shift.id} className='flex gap-2 items-center'>
                            <div className='text-sm font-bold text-gray-950 mr-12'>{`Shift ${index + 1}`}</div>
                            <label htmlFor={`startTime_${index}`} className='block text-sm font-medium text-gray-700'>Start Time</label>
                            <input
                                type='time'
                                name={`startTime_${index}`}
                                id={`startTime_${index}`}
                                value={shift.startTime}
                                onChange={(e) => {
                                    const newShifts = [...addInstructorShifts];
                                    newShifts[index].startTime = e.target.value;
                                    setAddInstructorShifts(newShifts);
                                }}
                                className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                            />
                            <label htmlFor={`endTime_${index}`} className='block text-sm font-medium text-gray-700'>End Time</label>
                            <input
                                type='time'
                                name={`endTime_${index}`}
                                id={`endTime_${index}`}
                                value={shift.endTime}
                                onChange={(e) => {
                                    const newShifts = [...addInstructorShifts];
                                    newShifts[index].endTime = e.target.value;
                                    setAddInstructorShifts(newShifts);
                                }}
                                className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                            />
                            <button
                                disabled={addInstructorShifts.length === 1}
                                onClick={() => {
                                    const newShifts = addInstructorShifts.filter((shft) => shft.id !== shift.id);
                                    setAddInstructorShifts(newShifts);
                                }}
                                className='text-red-900 bg-red-200 hover:bg-red-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                            >
                                <TrashIcon className='w-[18px] h-[18px]' />
                            </button>
                            <button

                                onClick={() => {
                                    handleAddRow()
                                }}
                                className='text-green-900 bg-green-200 hover:bg-green-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                            >
                                <PlusIcon className='w-[18px] h-[18px]' />
                            </button>
                        </div>
                    ))}
                </div>
            </div> */}
            <div className="flex items-center gap-2">
                <div className='mb-2 w-1/2'>
                    <label htmlFor='instructorImage' className='block text-sm font-medium text-gray-700'>Instructor Image</label>
                    <input
                        type='file'
                        name='instructorImage'
                        id='instructorImage'
                        onChange={handleUploadInstructorImage}
                        required
                        className='mt-[2px] block w-full px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                    />
                </div>
                <div className='w-1/2 flex justify-center'>
                    <img src={addInstructorImage ? IMAGE_BASE_URL + addInstructorImage : userPlaceholder} className='w-20 h-20 rounded-lg' />
                </div>
            </div>
        </div >
    )
    const handleAddRow = () => {
        setCount((count) => count + 1);
        // console.log(selectedDays)
        if (selectedDays == 'monday') {
            setaddInstructorShiftsMonday([...addInstructorShiftsMonday, { id: indexId + 1, startTime: '', endTime: '' }]);
        }
        if (selectedDays == 'tuesday') {
            setaddInstructorShiftsTuesday([...addInstructorShiftsTuesday, { id: indexId + 1, startTime: '', endTime: '' }]);
        }
        if (selectedDays == 'wednesday') {
            setaddInstructorShiftsWednesday([...addInstructorShiftsWednesday, { id: indexId + 1, startTime: '', endTime: '' }]);
        }
        if (selectedDays == 'thursday') {
            setaddInstructorShiftsThursday([...addInstructorShiftsThursday, { id: indexId + 1, startTime: '', endTime: '' }]);
        }
        if (selectedDays == 'friday') {
            setaddInstructorShiftsFriday([...addInstructorShiftsFriday, { id: indexId + 1, startTime: '', endTime: '' }]);
        }
        if (selectedDays == 'sunday') {
            setaddInstructorShiftsSunday([...addInstructorShiftsSunday, { id: indexId + 1, startTime: '', endTime: '' }]);
        }
        if (selectedDays == 'saturday') {
            setaddInstructorShiftsSaturday([...addInstructorShiftsSaturday, { id: indexId + 1, startTime: '', endTime: '' }]);
        }

    };
    return (
        <div className='w-full p-5'>
            {/* Add instructor modal */}
            <HeadlessUIModalComponent
                displayState={showAddInstructorModal}
                setDisplayState={setShowAddInstructorModal}
                headingChildren={<span>Add Instructor</span>}
                bodyChildren={addInstructorModalBody}
                footerChildren={
                    <div className='flex gap-4'>
                        <button
                            onClick={handleAddInstructor}
                            className='bg-indigo-600 flex items-center gap-1 py-2 px-4 rounded-lg hover:bg-indigo-700 text-white font-semibold'
                        >
                            Add Instructor
                        </button>
                        <button
                            onClick={() => setShowAddInstructorModal(false)}
                            className='bg-indigo-200 flex items-center gap-1 py-2 px-4 rounded-lg hover:bg-indigo-300 text-indigo-900 font-semibold'
                        >
                            Cancel
                        </button>
                    </div>
                }
                maxWidthClass='max-w-xl'
            />

            {/* Delete instructor modal */}
            <HeadlessUIModalComponent
                displayState={showDeleteInstructorModal}
                setDisplayState={setShowDeleteInstructorModal}
                headingChildren={<span>Delete Instructor</span>}
                bodyChildren={<span>Are you sure you want to delete <b>{allInstructors.find(ins => ins.id === deleteInstructorId)?.name}</b>?</span>}
                footerChildren={
                    <div className='flex gap-4'>
                        <button
                            onClick={() => {
                                let body = { id: deleteInstructorId }
                                instructorServiceApi.deleteInstructor(body)
                                    .then(responseData => {
                                        if (responseData.data.status === true) {
                                            toast.success(INSTRUCTOR_DELETED_SUCCESSFULLY);
                                            getInstructorList();
                                        } else {
                                            toast.error(INSTRUCTOR_COULDNT_DELETED);
                                        }
                                    })
                                    .catch(err => {
                                        console.error(err)
                                        toast.error(INSTRUCTOR_COULDNT_DELETED);
                                    })
                                setShowDeleteInstructorModal(false);
                            }}
                            className='bg-red-600 flex items-center gap-1 py-2 px-4 rounded-lg hover:bg-red-700 text-white font-semibold'
                        >
                            Delete
                        </button>
                        <button
                            onClick={() => setShowDeleteInstructorModal(false)}
                            className='bg-red-200 flex items-center gap-1 py-2 px-4 rounded-lg hover:bg-red-300 text-red-900 font-semibold'
                        >
                            Cancel
                        </button>
                    </div>
                }
                maxWidthClass='max-w-md'
            />
            <div className='flex mx-10 items-center justify-between' >
                <h1 className='text-2xl'>Instructors</h1>
                <button
                    onClick={() => setShowAddInstructorModal(true)}
                    className='bg-indigo-300 flex items-center gap-1 py-2 px-4 rounded-lg hover:bg-indigo-400 text-indigo-900 font-semibold'
                >
                    <PlusIcon className="w-5 h-5" /> Add Instructor
                </button>
            </div>
            <div className='mt-5 w-10/12 mx-auto'>
                <ul>
                    {instructorsList}
                </ul>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    allServices: state.allServices
})
export default connect(mapStateToProps, null)(InstructorsList)