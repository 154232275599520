import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import userPlaceholder from '../../../../../assets/images/userPlaceholder.png'
import { Link } from 'react-router-dom';
import { Switch } from '@headlessui/react'
import { CheckIcon, MinusIcon, PlusIcon, TrashIcon, XMarkIcon, ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { IMAGE_BASE_URL, defaultClassList } from '../../../../../commonServices/commonDataService';
import ReactQuill from 'react-quill';
import { quillModules } from '../../../../../commonServices/quillModules';
import MultiselectListbox from '../../../../shared-components/ListboxMultiselect/MultiselectListbox';
import { connect } from 'react-redux';
import { imageFileServiceApi, instructorServiceApi } from '../../../../../commonServices/apiService';
import { toast } from 'react-toastify';
import { IMAGE_UPDATED_SUCCESSFULLY, INSTRUCTOR_COULDNT_DELETED, INSTRUCTOR_DELETED_SUCCESSFULLY, INSTRUCTOR_UPDATED_SUCCESSFULLY } from '../../../../../commonServices/messageConstants';
import HeadlessUIModalComponent from '../../../../shared-components/modal/HeadlessUIModal';
import { Tab } from '@headlessui/react';

const InstructorsViewEditDetails = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const [profilePic, setProfilePic] = useState(userPlaceholder)

    const reactLocation = useLocation();
    const locationState = reactLocation?.state;
    const [instructorData, setInstructorData] = useState({});

    const [showDeleteInstructorModal, setShowDeleteInstructorModal] = useState(false);

    //shanoo vish
    const [indexId, setCount] = useState(0);
    const [addInstructorShiftsMonday, setaddInstructorShiftsMonday] = useState([{ id: 0, startTime: '', endTime: '' }])
    const [addInstructorShiftsTuesday, setaddInstructorShiftsTuesday] = useState([{ id: 0, startTime: '', endTime: '' }])
    const [addInstructorShiftsWednesday, setaddInstructorShiftsWednesday] = useState([{ id: 0, startTime: '', endTime: '' }])
    const [addInstructorShiftsThursday, setaddInstructorShiftsThursday] = useState([{ id: 0, startTime: '', endTime: '' }])
    const [addInstructorShiftsFriday, setaddInstructorShiftsFriday] = useState([{ id: 0, startTime: '', endTime: '' }])
    const [addInstructorShiftsSaturday, setaddInstructorShiftsSaturday] = useState([{ id: 0, startTime: '', endTime: '' }])
    const [addInstructorShiftsSunday, setaddInstructorShiftsSunday] = useState([{ id: 0, startTime: '', endTime: '' }])
    const [selectedDays, setSelectedDays] = useState('monday')
    const [addInstructorWorkingDays, setAddInstructorWorkingDays] = useState({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
    })
    //selected working
    const getDayName = (e) => {
        setSelectedDays('')
        setSelectedDays(e);

    }
    const resteHandlerRecord = (value) => {
        if (selectedDays == 'monday') {
            addInstructorShiftsMonday.forEach(element => {
                if (element.id == value.id) {
                    element.startTime = ''
                    element.endTime = ''
                }
            });
            setaddInstructorShiftsMonday([...addInstructorShiftsMonday]);
        }
        if (selectedDays == 'tuesday') {
            addInstructorShiftsTuesday.forEach(element => {
                if (element.id == value.id) {
                    element.startTime = ''
                    element.endTime = ''
                }
            });
            setaddInstructorShiftsTuesday([...addInstructorShiftsMonday]);
        }
        if (selectedDays == 'wednesday') {
            addInstructorShiftsWednesday.forEach(element => {
                if (element.id == value.id) {
                    element.startTime = ''
                    element.endTime = ''
                }
            });
            setaddInstructorShiftsWednesday([...addInstructorShiftsWednesday]);
        }
        if (selectedDays == 'thursday') {
            addInstructorShiftsThursday.forEach(element => {
                if (element.id == value.id) {
                    element.startTime = ''
                    element.endTime = ''
                }
            });
            setaddInstructorShiftsThursday([...addInstructorShiftsThursday]);
        }
        if (selectedDays == 'friday') {
            addInstructorShiftsFriday.forEach(element => {
                if (element.id == value.id) {
                    element.startTime = ''
                    element.endTime = ''
                }
            });
            setaddInstructorShiftsFriday([...addInstructorShiftsFriday]);
        }
        if (selectedDays == 'sunday') {
            addInstructorShiftsSunday.forEach(element => {
                if (element.id == value.id) {
                    element.startTime = ''
                    element.endTime = ''
                }
            });
            setaddInstructorShiftsSunday([...addInstructorShiftsSunday]);
        }
        if (selectedDays == 'saturday') {
            addInstructorShiftsSaturday.forEach(element => {
                if (element.id == value.id) {
                    element.startTime = ''
                    element.endTime = ''
                }
            });
            setaddInstructorShiftsSaturday([...addInstructorShiftsSaturday]);
        }
    }
    useEffect(() => {
        if (locationState) {
            setInstructorData(locationState.instructor)
            setProfilePic(locationState?.instructor?.profileImage)
            const workingData = JSON.parse(locationState?.instructor?.workDays)
            console.log(workingData)
            if (workingData.monday == true) {
                addInstructorWorkingDays.monday = true
            }
            if (workingData.tuesday == true) {
                addInstructorWorkingDays.tuesday = true
            }
            if (workingData.wednesday == true) {
                addInstructorWorkingDays.wednesday = true
            }
            if (workingData.thursday == true) {
                addInstructorWorkingDays.thursday = true
            }
            if (workingData.friday == true) {
                addInstructorWorkingDays.friday = true
            }
            if (workingData.saturday == true) {
                addInstructorWorkingDays.saturday = true
            }
            if (workingData.sunday == true) {
                addInstructorWorkingDays.sunday = true
            }
            //shanoo vish

            const shiftTiming = JSON.parse(locationState.instructor.shiftTiming)
            if (shiftTiming.monday?.length > 0) {
                setaddInstructorShiftsMonday([...shiftTiming.monday])
            }
            if (shiftTiming.tuesday?.length > 0) {
                setaddInstructorShiftsTuesday([...shiftTiming.tuesday])
            }
            if (shiftTiming.wednesday?.length > 0) {
                setaddInstructorShiftsWednesday([...shiftTiming.wednesday])
            }
            if (shiftTiming.thursday?.length > 0) {
                setaddInstructorShiftsThursday([...shiftTiming.thursday])
            }
            if (shiftTiming.friday?.length > 0) {
                setaddInstructorShiftsFriday([...shiftTiming.friday])
            }
            if (shiftTiming.saturday?.length > 0) {
                setaddInstructorShiftsSaturday([...shiftTiming.saturday])
            }
            if (shiftTiming.sunday?.length > 0) {
                setaddInstructorShiftsSunday([...shiftTiming.sunday])
            }

        }


    }, [locationState])
    const handleAddRow = () => {
        setCount((count) => count + 1);

        if (selectedDays == 'monday') {
            setaddInstructorShiftsMonday([...addInstructorShiftsMonday, { id: indexId + 1, startTime: '', endTime: '' }]);
        }
        if (selectedDays == 'tuesday') {
            setaddInstructorShiftsTuesday([...addInstructorShiftsTuesday, { id: indexId + 1, startTime: '', endTime: '' }]);
        }
        if (selectedDays == 'wednesday') {
            setaddInstructorShiftsWednesday([...addInstructorShiftsWednesday, { id: indexId + 1, startTime: '', endTime: '' }]);
        }
        if (selectedDays == 'thursday') {
            setaddInstructorShiftsThursday([...addInstructorShiftsThursday, { id: indexId + 1, startTime: '', endTime: '' }]);
        }
        if (selectedDays == 'friday') {
            setaddInstructorShiftsFriday([...addInstructorShiftsFriday, { id: indexId + 1, startTime: '', endTime: '' }]);
        }
        if (selectedDays == 'sunday') {
            setaddInstructorShiftsSunday([...addInstructorShiftsSunday, { id: indexId + 1, startTime: '', endTime: '' }]);
        }
        if (selectedDays == 'saturday') {
            setaddInstructorShiftsSaturday([...addInstructorShiftsSaturday, { id: indexId + 1, startTime: '', endTime: '' }]);
        }

    };

    const handleProfilePicChange = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        let fdata = new FormData();
        fdata.append('image', file);
        fdata.append('prevImageName', profilePic);
        if (file) {
            imageFileServiceApi.uploadImage(fdata)
                .then((response) => {
                    setProfilePic(response.data.data.filename)
                    saveInstructorUpdatedDetails()
                    toast.success(IMAGE_UPDATED_SUCCESSFULLY)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const saveInstructorUpdatedDetails = (from = 'image') => {


        var shiftTiming = {
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
            sunday: ''
        }
        if (addInstructorShiftsMonday.length > 0) {
            shiftTiming.monday = addInstructorShiftsMonday.filter(item => item.startTime !== '' || item.endTime !== '')
            if (shiftTiming?.monday?.length > 0) {
                addInstructorWorkingDays.monday = true
            } else {
                addInstructorWorkingDays.monday = false
            }
        }
        if (addInstructorShiftsTuesday.length > 0) {
            shiftTiming.tuesday = addInstructorShiftsTuesday.filter(item => item.startTime !== '' || item.endTime !== '')

            if (shiftTiming?.tuesday?.length > 0) {
                addInstructorWorkingDays.tuesday = true
            } else {
                addInstructorWorkingDays.tuesday = false
            }
        }
        if (addInstructorShiftsWednesday.length > 0) {
            shiftTiming.wednesday = addInstructorShiftsWednesday.filter(item => item.startTime !== '' || item.endTime !== '')
            if (shiftTiming?.wednesday?.length > 0) {
                addInstructorWorkingDays.wednesday = true
            } else {
                addInstructorWorkingDays.wednesday = false
            }
        }
        if (addInstructorShiftsThursday.length > 0) {
            shiftTiming.thursday = addInstructorShiftsThursday.filter(item => item.startTime !== '' || item.endTime !== '')
            if (shiftTiming?.thursday?.length > 0) {
                addInstructorWorkingDays.thursday = true
            } else {
                addInstructorWorkingDays.thursday = false
            }
        }
        if (addInstructorShiftsFriday.length > 0) {
            shiftTiming.friday = addInstructorShiftsFriday.filter(item => item.startTime !== '' || item.endTime !== '')
            if (shiftTiming?.friday?.length > 0) {
                addInstructorWorkingDays.friday = true
            } else {
                addInstructorWorkingDays.friday = false
            }
        }
        if (addInstructorShiftsSaturday.length > 0) {
            shiftTiming.saturday = addInstructorShiftsSaturday.filter(item => item.startTime !== '' || item.endTime !== '')
            if (shiftTiming?.saturday?.length > 0) {
                addInstructorWorkingDays.saturday = true
            } else {
                addInstructorWorkingDays.saturday = false
            }
        }
        if (addInstructorShiftsSunday.length > 0) {
            shiftTiming.sunday = addInstructorShiftsSunday.filter(item => item.startTime !== '' || item.endTime !== '')
            if (shiftTiming?.sunday?.length > 0) {
                addInstructorWorkingDays.sunday = true
            } else {
                addInstructorWorkingDays.sunday = false
            }
        }
        let body = {
            id: params.id,
            data: {
                name: instructorData.name,
                address: instructorData.address,
                contactNumber: instructorData.contactNumber,
                description: instructorData.description,
                skills: instructorData.skills,
                associatedServices: instructorData.associatedServices,
                associatedClasses: instructorData.associatedClasses,
                workDays: addInstructorWorkingDays,
                shiftTiming: JSON.stringify(shiftTiming),
                status: instructorData.status,
                profileImage: profilePic
            }
        }
        // console.log(body)
        instructorServiceApi.updateInstructor(body)
            .then((response) => {
                // console.log(response)
                if (response.data.status === true) {
                    if (from === 'save') {
                        toast.success(INSTRUCTOR_UPDATED_SUCCESSFULLY)
                        navigate('/inHouseTeam')
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }


    return (
        <div className='container mx-auto px-4 mt-10'>
            {/* Deletion modal */}
            <HeadlessUIModalComponent
                displayState={showDeleteInstructorModal}
                setDisplayState={setShowDeleteInstructorModal}
                headingChildren={<span>Delete Instructor</span>}
                bodyChildren={<span>Are you sure you want to delete <b>{instructorData?.name}</b>?</span>}
                footerChildren={
                    <div className='flex gap-4'>
                        <button
                            onClick={() => {
                                let body = { id: params.id }
                                instructorServiceApi.deleteInstructor(body)
                                    .then(responseData => {
                                        if (responseData.data.status === true) {
                                            toast.success(INSTRUCTOR_DELETED_SUCCESSFULLY);
                                            navigate('/inHouseTeam');
                                        } else {
                                            toast.error(INSTRUCTOR_COULDNT_DELETED);
                                        }
                                    })
                                    .catch(err => {
                                        // console.error(err)
                                        toast.error(INSTRUCTOR_COULDNT_DELETED);
                                    })
                                setShowDeleteInstructorModal(false);
                            }}
                            className='bg-red-600 flex items-center gap-1 py-2 px-4 rounded-lg hover:bg-red-700 text-white font-semibold'
                        >
                            Delete
                        </button>
                        <button
                            onClick={() => setShowDeleteInstructorModal(false)}
                            className='bg-red-200 flex items-center gap-1 py-2 px-4 rounded-lg hover:bg-red-300 text-red-900 font-semibold'
                        >
                            Cancel
                        </button>
                    </div>
                }
                maxWidthClass='max-w-md'
            />
            <h1
                className='text-2xl font-semibold text-center mb-5'
            >
                Manage Instructor #{params.id}
            </h1>
            <div className="flex justify-between">
                <div className='mb-5 mx-14 flex flex-col md:flex-row items-center gap-5'>
                    <img
                        src={profilePic ? IMAGE_BASE_URL + profilePic : userPlaceholder}
                        className='rounded-3xl w-40 h-40 border border-gray-400'
                    />
                    <div>
                        <label
                            htmlFor="avatar"
                            className='bg-indigo-200 px-4 py-2 rounded text-indigo-900 text-sm font-bold hover:bg-indigo-300 cursor-pointer'
                        >
                            Change avatar
                        </label>
                        <input
                            type="file"
                            name="avatar"
                            accept='.jpg, .jpeg, .gif, .png'
                            id="avatar"
                            className='hidden'
                            onChange={handleProfilePicChange}
                        />
                        <p className='text-sm text-center mt-2 text-gray-600'>JPG, GIF or PNG.</p>
                    </div>
                </div>
                <div className='flex mx-auto items-center gap-2'>
                    <label>Status</label>
                    <Switch
                        checked={instructorData?.status}
                        onChange={() => setInstructorData({ ...instructorData, status: !instructorData?.status })}
                        className={`border ${instructorData?.status ? 'bg-green-300 border-green-500' : 'bg-red-300 border-red-500'} relative inline-flex h-[34px] w-[70px] shrink-0 cursor-pointer rounded-full border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
                    >
                        <span
                            aria-hidden="true"
                            className={`${instructorData?.status ? 'translate-x-9 bg-green-700/90' : 'translate-x-0 bg-red-700/90'}
                                                  pointer-events-none text-white inline-block h-[30px] w-[30px] transform mt-[1px] ml-[1px] rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`}
                        >
                            {instructorData?.status
                                ?
                                <CheckIcon className='w-6 absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2' />
                                :
                                <XMarkIcon className='w-6 absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2' />
                            }
                        </span>
                    </Switch>
                </div>
            </div>
            <div className="grid grid-cols-2">
                <div className='mb-3 w-4/5 mx-auto text-left'>
                    <label
                        htmlFor="instructorName"
                        className='block'
                    >
                        Name
                    </label>
                    <input
                        type="text"
                        id='instructorName'
                        placeholder='John Doe'
                        value={instructorData?.name}
                        onChange={(e) => setInstructorData({ ...instructorData, name: e.target.value })}
                        className='w-full border-2 border-gray-400 focus:border-indigo-600 outline-none rounded-lg p-2'
                    />
                </div>
                <div className='mb-3 w-4/5 mx-auto text-left'>
                    <label
                        htmlFor="instructorAddress"
                        className='block'
                    >
                        Address
                    </label>
                    <input
                        type="text"
                        id='instructorAddress'
                        value={instructorData?.address}
                        onChange={(e) => setInstructorData({ ...instructorData, address: e.target.value })}
                        placeholder='123, Main Street, City, State, Country'
                        className='w-full border-2 border-gray-400 focus:border-indigo-600 outline-none rounded-lg p-2'
                    />
                </div>
                <div className='mb-3 w-4/5 mx-auto text-left'>
                    <label
                        htmlFor="instructorEmail"
                        className='block'
                    >
                        Email
                    </label>
                    <input
                        type="email"
                        id='instructorEmail'
                        value={instructorData?.email}
                        onChange={(e) => setInstructorData({ ...instructorData, email: e.target.value })}
                        placeholder='user@example.com'
                        className='w-full border-2 border-gray-400 focus:border-indigo-600 outline-none rounded-lg p-2'
                    />
                </div>
                <div className='mb-3 w-4/5 mx-auto text-left'>
                    <label
                        htmlFor="instructorContact"
                        className='block'
                    >
                        Contact
                    </label>
                    <div className='flex items-center relative'>
                        <span className='text-gray-800 absolute left-1'>{"+91"}</span>
                        <input
                            type="text"
                            id='instructorContact'
                            value={instructorData?.contactNumber}
                            onChange={(e) => setInstructorData({ ...instructorData, contactNumber: e.target.value })}
                            placeholder='9123456789'
                            className='w-full border-2 pl-10 border-gray-400 focus:border-indigo-600 outline-none rounded-lg p-2'
                        />
                    </div>
                </div>
                <div className='mb-3 w-4/5 mx-auto text-left'>
                    <label
                        htmlFor="instructorDescription"
                        className='block'
                    >
                        Description
                    </label>

                    <ReactQuill
                        id='instructorDescription'
                        modules={quillModules}
                        value={instructorData?.description}
                        onChange={(value) => {

                            setInstructorData({
                                ...instructorData, description: value

                            })
                        }}
                        className='w-full border-2 border-gray-400 focus-within:border-indigo-600 outline-none'
                    />
                </div>
                <div className='mb-3 w-4/5 mx-auto text-left'>
                    <label
                        htmlFor="instructorSkills"
                        className='block'
                    >
                        Skills (comma separated)
                    </label>
                    <textarea
                        id='instructorSkills'
                        rows={3}
                        placeholder='Skill 1, Skill 2, Skill 3'
                        value={instructorData?.skills ? JSON.parse(instructorData?.skills).join(', ') : instructorData?.skills}
                        onChange={(e) => setInstructorData({ ...instructorData, skills: JSON.stringify(e.target.value.split(', ')) })}
                        className='w-full border-2 border-gray-400 focus:border-indigo-600 outline-none rounded-lg p-2'
                    />
                </div>
                <div className='mb-3 w-4/5 mx-auto text-left'>
                    <label
                        htmlFor="instructorServices"
                        className='block'
                    >
                        Associated Services
                    </label>
                    <MultiselectListbox
                        items={props.allServices.allServicesData}
                        label={""}
                        nameVariable={"treatmentServiceName"}
                        type={"services"}
                        selectedItems={instructorData?.associatedServices ? JSON.parse(instructorData?.associatedServices) : []}
                        setSelectedItems={(selectedOptions) => setInstructorData({ ...instructorData, associatedServices: JSON.stringify(selectedOptions) })}
                    />
                </div>
                <div className='mb-3 w-4/5 mx-auto text-left'>
                    <label
                        htmlFor="instructorClasses"
                        className='block'
                    >
                        Associated Classes
                    </label>
                    <MultiselectListbox
                        items={defaultClassList}
                        label={""}
                        nameVariable={"title"}
                        type={"classes"}
                        selectedItems={instructorData?.associatedClasses ? JSON.parse(instructorData?.associatedClasses) : []}
                        setSelectedItems={(selectedOptions) => setInstructorData({ ...instructorData, associatedClasses: JSON.stringify(selectedOptions) })}
                    />
                </div>
            </div>
            <div className='mb-3 mx-auto w-[90%] text-left'>
                <label
                    htmlFor="instructorWorkDays"
                    className='block'
                >
                    Working Days
                </label>
              
                <div className=" items-center gap-5 mt-2">
                    <Tab.Group>
                        <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">

                            {Object.keys(instructorData?.workDays ? JSON.parse(instructorData?.workDays) : {}).map((day) => {

                                let instructorWorkingDays = instructorData?.workDays
                                instructorWorkingDays = JSON.parse(instructorWorkingDays)
                                return (
                                    <Tab key={day}
                                        className="inline-block  rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white active"
                                        onClick={(e) => {
                                            getDayName(day)
                                        }}>
                                        <label
                                            htmlFor={`day_${day}`}
                                            className={`flex items-center gap-0.5 cursor-pointer 
                                                ${day == selectedDays ? 'bg-indigo-500 text-white' : 'bg-gray-300 text-gray-700'} px-3 py-1.5 rounded-md shadow-sm focus:outline-none`}
                                        >
                                            {instructorWorkingDays[day]}
                                            {
                                                <PlusIcon className='w-4 h-4 inline-block' />
                                            }
                                            {day.slice(0, 3).toUpperCase()}
                                        </label>

                                        < input
                                            type='checkbox'
                                            name={day}
                                            id={`day_${day}`}
                                            checked={instructorWorkingDays[day]}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setInstructorData({ ...instructorData, workDays: JSON.stringify({ ...instructorWorkingDays, [day]: true }) });
                                                } else {
                                                    setInstructorData({ ...instructorData, workDays: JSON.stringify({ ...instructorWorkingDays, [day]: false }) });
                                                }
                                            }
                                            }
                                            className='hidden'
                                        />
                                    </Tab>
                                )
                            })}
                        </Tab.List>
                        <Tab.Panels>
                            <Tab.Panel><div className="mb-2">
                                <label htmlFor='shifts' className='block text-sm font-medium text-gray-700'>
                                    Working Shifts Monday
                                </label>
                                <div className=" flex-col items-center gap-2 mt-2">

                                    {addInstructorShiftsMonday.map((shift, index) => (

                                        <div key={shift.id} className='flex gap-2 items-center'>
                                            <div className='text-sm font-bold text-gray-950 mr-12'>{`Shift ${index + 1}`}</div>
                                            <label htmlFor={`startTime_${index}`} className='block text-sm font-medium text-gray-700'>Start Time</label>
                                            <input
                                                type='time'
                                                name={`startTime_${index}`}
                                                id={`startTime_${index}`}
                                                value={shift.startTime}
                                                onChange={(e) => {
                                                    const newShifts = [...addInstructorShiftsMonday];
                                                    newShifts[index].startTime = e.target.value;
                                                    setaddInstructorShiftsMonday(newShifts);
                                                }}
                                                className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                            />
                                            <label htmlFor={`endTime_${index}`} className='block text-sm font-medium text-gray-700'>End Time</label>
                                            <input
                                                type='time'
                                                name={`endTime_${index}`}
                                                id={`endTime_${index}`}
                                                value={shift.endTime}
                                                onChange={(e) => {
                                                    const newShifts = [...addInstructorShiftsMonday];
                                                    newShifts[index].endTime = e.target.value;
                                                    setaddInstructorShiftsMonday(newShifts);
                                                }}
                                                className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                            />
                                            <button
                                                disabled={addInstructorShiftsMonday?.length === 1}
                                                onClick={() => {
                                                    const newShifts = addInstructorShiftsMonday.filter((shft) => shft.id !== shift.id);
                                                    setaddInstructorShiftsMonday(newShifts);
                                                }}
                                                className='text-red-900 bg-red-200 hover:bg-red-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                            >
                                                <TrashIcon className='w-[18px] h-[18px]' />
                                            </button>
                                            <button

                                                onClick={() => {
                                                    handleAddRow()
                                                }}
                                                className='text-green-900 bg-green-200 hover:bg-green-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                            >
                                                <PlusIcon className='w-[18px] h-[18px]' />
                                            </button>
                                            <button
                                                onClick={() => {
                                                    resteHandlerRecord(shift)
                                                }}
                                                className='text-gray-900 bg-gray-200 hover:bg-gray-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                            >
                                                <ArrowUturnLeftIcon className='w-[18px] h-[18px]' />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div></Tab.Panel>
                            <Tab.Panel><div className="mb-2">

                                <label htmlFor='shifts' className='block text-sm font-medium text-gray-700'>
                                    Working Shifts Tuesday
                                </label>
                                <div className="flex-col items-center gap-2 mt-2">

                                    {addInstructorShiftsTuesday.map((shift, index) => (

                                        <div key={shift.id} className='flex gap-2 items-center'>
                                            <div className='text-sm font-bold text-gray-950 mr-12'>{`Shift ${index + 1}`}</div>
                                            <label htmlFor={`startTime_${index}`} className='block text-sm font-medium text-gray-700'>Start Time</label>
                                            <input
                                                type='time'
                                                name={`startTime_${index}`}
                                                id={`startTime_${index}`}
                                                value={shift.startTime}
                                                onChange={(e) => {
                                                    const newShifts = [...addInstructorShiftsTuesday];
                                                    newShifts[index].startTime = e.target.value;
                                                    setaddInstructorShiftsTuesday(newShifts);
                                                }}
                                                className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                            />
                                            <label htmlFor={`endTime_${index}`} className='block text-sm font-medium text-gray-700'>End Time</label>
                                            <input
                                                type='time'
                                                name={`endTime_${index}`}
                                                id={`endTime_${index}`}
                                                value={shift.endTime}
                                                onChange={(e) => {
                                                    const newShifts = [...addInstructorShiftsTuesday];
                                                    newShifts[index].endTime = e.target.value;
                                                    setaddInstructorShiftsTuesday(newShifts);
                                                }}
                                                className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                            />
                                            <button
                                                disabled={addInstructorShiftsTuesday?.length === 1}
                                                onClick={() => {
                                                    const newShifts = addInstructorShiftsTuesday.filter((shft) => shft.id !== shift.id);
                                                    setaddInstructorShiftsTuesday(newShifts);
                                                }}
                                                className='text-red-900 bg-red-200 hover:bg-red-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                            >
                                                <TrashIcon className='w-[18px] h-[18px]' />
                                            </button>
                                            <button

                                                onClick={() => {
                                                    handleAddRow()
                                                }}
                                                className='text-green-900 bg-green-200 hover:bg-green-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                            >
                                                <PlusIcon className='w-[18px] h-[18px]' />
                                            </button>
                                            <button
                                                onClick={() => {
                                                    resteHandlerRecord(shift)
                                                }}
                                                className='text-gray-900 bg-gray-200 hover:bg-gray-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                            >
                                                <ArrowUturnLeftIcon className='w-[18px] h-[18px]' />
                                            </button>

                                        </div>
                                    ))}
                                </div>
                            </div></Tab.Panel>
                            <Tab.Panel><div className="mb-2">

                                <label htmlFor='shifts' className='block text-sm font-medium text-gray-700'>
                                    Working Shifts Wednesday
                                </label>
                                <div className="flex-col items-center gap-2 mt-2">
                                    {addInstructorShiftsWednesday.map((shift, index) => (

                                        <div key={shift.id} className='flex gap-2 items-center'>
                                            <div className='text-sm font-bold text-gray-950 mr-12'>{`Shift ${index + 1}`}</div>
                                            <label htmlFor={`startTime_${index}`} className='block text-sm font-medium text-gray-700'>Start Time</label>
                                            <input
                                                type='time'
                                                name={`startTime_${index}`}
                                                id={`startTime_${index}`}
                                                value={shift.startTime}
                                                onChange={(e) => {
                                                    const newShifts = [...addInstructorShiftsWednesday];
                                                    newShifts[index].startTime = e.target.value;
                                                    setaddInstructorShiftsWednesday(newShifts);
                                                }}
                                                className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                            />
                                            <label htmlFor={`endTime_${index}`} className='block text-sm font-medium text-gray-700'>End Time</label>
                                            <input
                                                type='time'
                                                name={`endTime_${index}`}
                                                id={`endTime_${index}`}
                                                value={shift.endTime}
                                                onChange={(e) => {
                                                    const newShifts = [...addInstructorShiftsWednesday];
                                                    newShifts[index].endTime = e.target.value;
                                                    setaddInstructorShiftsWednesday(newShifts);
                                                }}
                                                className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                            />
                                            <button
                                                disabled={addInstructorShiftsWednesday?.length === 1}
                                                onClick={() => {
                                                    const newShifts = addInstructorShiftsWednesday.filter((shft) => shft.id !== shift.id);
                                                    setaddInstructorShiftsWednesday(newShifts);
                                                }}
                                                className='text-red-900 bg-red-200 hover:bg-red-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                            >
                                                <TrashIcon className='w-[18px] h-[18px]' />
                                            </button>
                                            <button

                                                onClick={() => {
                                                    handleAddRow()
                                                }}
                                                className='text-green-900 bg-green-200 hover:bg-green-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                            >
                                                <PlusIcon className='w-[18px] h-[18px]' />
                                            </button>
                                            <button
                                                onClick={() => {
                                                    resteHandlerRecord(shift)
                                                }}
                                                className='text-gray-900 bg-gray-200 hover:bg-gray-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                            >
                                                <ArrowUturnLeftIcon className='w-[18px] h-[18px]' />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div></Tab.Panel>
                            <Tab.Panel><div className="mb-2">

                                <label htmlFor='shifts' className='block text-sm font-medium text-gray-700'>
                                    Working Shifts Thursday
                                </label>
                                <div className="flex-col items-center gap-2 mt-2">
                                    {addInstructorShiftsThursday.map((shift, index) => (

                                        <div key={shift.id} className='flex gap-2 items-center'>
                                            <div className='text-sm font-bold text-gray-950 mr-12'>{`Shift ${index + 1}`}</div>
                                            <label htmlFor={`startTime_${index}`} className='block text-sm font-medium text-gray-700'>Start Time</label>
                                            <input
                                                type='time'
                                                name={`startTime_${index}`}
                                                id={`startTime_${index}`}
                                                value={shift.startTime}
                                                onChange={(e) => {
                                                    const newShifts = [...addInstructorShiftsThursday];
                                                    newShifts[index].startTime = e.target.value;
                                                    setaddInstructorShiftsThursday(newShifts);
                                                }}
                                                className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                            />
                                            <label htmlFor={`endTime_${index}`} className='block text-sm font-medium text-gray-700'>End Time</label>
                                            <input
                                                type='time'
                                                name={`endTime_${index}`}
                                                id={`endTime_${index}`}
                                                value={shift.endTime}
                                                onChange={(e) => {
                                                    const newShifts = [...addInstructorShiftsThursday];
                                                    newShifts[index].endTime = e.target.value;
                                                    setaddInstructorShiftsThursday(newShifts);
                                                }}
                                                className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                            />
                                            <button
                                                disabled={addInstructorShiftsThursday?.length === 1}
                                                onClick={() => {
                                                    const newShifts = addInstructorShiftsThursday.filter((shft) => shft.id !== shift.id);
                                                    setaddInstructorShiftsThursday(newShifts);
                                                }}
                                                className='text-red-900 bg-red-200 hover:bg-red-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                            >
                                                <TrashIcon className='w-[18px] h-[18px]' />
                                            </button>
                                            <button

                                                onClick={() => {
                                                    handleAddRow()
                                                }}
                                                className='text-green-900 bg-green-200 hover:bg-green-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                            >
                                                <PlusIcon className='w-[18px] h-[18px]' />
                                            </button>
                                            <button
                                                onClick={() => {
                                                    resteHandlerRecord(shift)
                                                }}
                                                className='text-gray-900 bg-gray-200 hover:bg-gray-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                            >
                                                <ArrowUturnLeftIcon className='w-[18px] h-[18px]' />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div></Tab.Panel>
                            <Tab.Panel><div className="mb-2">

                                <label htmlFor='shifts' className='block text-sm font-medium text-gray-700'>
                                    Working Shifts Friday
                                </label>
                                <div className="flex-col items-center gap-2 mt-2">
                                    {addInstructorShiftsFriday.map((shift, index) => (

                                        <div key={shift.id} className='flex gap-2 items-center'>
                                            <div className='text-sm font-bold text-gray-950 mr-12'>{`Shift ${index + 1}`}</div>
                                            <label htmlFor={`startTime_${index}`} className='block text-sm font-medium text-gray-700'>Start Time</label>
                                            <input
                                                type='time'
                                                name={`startTime_${index}`}
                                                id={`startTime_${index}`}
                                                value={shift.startTime}
                                                onChange={(e) => {
                                                    const newShifts = [...addInstructorShiftsFriday];
                                                    newShifts[index].startTime = e.target.value;
                                                    setaddInstructorShiftsFriday(newShifts);
                                                }}
                                                className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                            />
                                            <label htmlFor={`endTime_${index}`} className='block text-sm font-medium text-gray-700'>End Time</label>
                                            <input
                                                type='time'
                                                name={`endTime_${index}`}
                                                id={`endTime_${index}`}
                                                value={shift.endTime}
                                                onChange={(e) => {
                                                    const newShifts = [...addInstructorShiftsFriday];
                                                    newShifts[index].endTime = e.target.value;
                                                    setaddInstructorShiftsFriday(newShifts);
                                                }}
                                                className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                            />
                                            <button
                                                disabled={addInstructorShiftsFriday?.length === 1}
                                                onClick={() => {
                                                    const newShifts = addInstructorShiftsFriday.filter((shft) => shft.id !== shift.id);
                                                    setaddInstructorShiftsFriday(newShifts);
                                                }}
                                                className='text-red-900 bg-red-200 hover:bg-red-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                            >
                                                <TrashIcon className='w-[18px] h-[18px]' />
                                            </button>
                                            <button

                                                onClick={() => {
                                                    handleAddRow()
                                                }}
                                                className='text-green-900 bg-green-200 hover:bg-green-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                            >
                                                <PlusIcon className='w-[18px] h-[18px]' />
                                            </button>
                                            <button
                                                onClick={() => {
                                                    resteHandlerRecord(shift)
                                                }}
                                                className='text-gray-900 bg-gray-200 hover:bg-gray-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                            >
                                                <ArrowUturnLeftIcon className='w-[18px] h-[18px]' />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div></Tab.Panel>
                            <Tab.Panel><div className="mb-2">

                                <label htmlFor='shifts' className='block text-sm font-medium text-gray-700'>
                                    Working Shifts Saturday
                                </label>
                                <div className="flex-col items-center gap-2 mt-2">
                                    {addInstructorShiftsSaturday.map((shift, index) => (

                                        <div key={shift.id} className='flex gap-2 items-center'>
                                            <div className='text-sm font-bold text-gray-950 mr-12'>{`Shift ${index + 1}`}</div>
                                            <label htmlFor={`startTime_${index}`} className='block text-sm font-medium text-gray-700'>Start Time</label>
                                            <input
                                                type='time'
                                                name={`startTime_${index}`}
                                                id={`startTime_${index}`}
                                                value={shift.startTime}
                                                onChange={(e) => {
                                                    const newShifts = [...addInstructorShiftsSaturday];
                                                    newShifts[index].startTime = e.target.value;
                                                    setaddInstructorShiftsSaturday(newShifts);
                                                }}
                                                className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                            />
                                            <label htmlFor={`endTime_${index}`} className='block text-sm font-medium text-gray-700'>End Time</label>
                                            <input
                                                type='time'
                                                name={`endTime_${index}`}
                                                id={`endTime_${index}`}
                                                value={shift.endTime}
                                                onChange={(e) => {
                                                    const newShifts = [...addInstructorShiftsSaturday];
                                                    newShifts[index].endTime = e.target.value;
                                                    setaddInstructorShiftsSaturday(newShifts);
                                                }}
                                                className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                            />
                                            <button
                                                disabled={addInstructorShiftsSaturday?.length === 1}
                                                onClick={() => {
                                                    const newShifts = addInstructorShiftsSaturday.filter((shft) => shft.id !== shift.id);
                                                    setaddInstructorShiftsSaturday(newShifts);
                                                }}
                                                className='text-red-900 bg-red-200 hover:bg-red-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                            >
                                                <TrashIcon className='w-[18px] h-[18px]' />
                                            </button>
                                            <button

                                                onClick={() => {
                                                    handleAddRow()
                                                }}
                                                className='text-green-900 bg-green-200 hover:bg-green-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                            >
                                                <PlusIcon className='w-[18px] h-[18px]' />
                                            </button>
                                            <button
                                                onClick={() => {
                                                    resteHandlerRecord(shift)
                                                }}
                                                className='text-gray-900 bg-gray-200 hover:bg-gray-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                            >
                                                <ArrowUturnLeftIcon className='w-[18px] h-[18px]' />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div></Tab.Panel>
                            <Tab.Panel><div className="mb-2">

                                <label htmlFor='shifts' className='block text-sm font-medium text-gray-700'>
                                    Working Shifts Sunday
                                </label>
                                <div className=" flex-col items-center gap-2 mt-2">
                                    {addInstructorShiftsSunday.map((shift, index) => (

                                        <div key={shift.id} className='flex gap-2 items-center'>
                                            <div className='text-sm font-bold text-gray-950 mr-12'>{`Shift ${index + 1}`}</div>
                                            <label htmlFor={`startTime_${index}`} className='block text-sm font-medium text-gray-700'>Start Time</label>
                                            <input
                                                type='time'
                                                name={`startTime_${index}`}
                                                id={`startTime_${index}`}
                                                value={shift.startTime}
                                                onChange={(e) => {
                                                    const newShifts = [...addInstructorShiftsSunday];
                                                    newShifts[index].startTime = e.target.value;
                                                    setaddInstructorShiftsSunday(newShifts);
                                                }}
                                                className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                            />
                                            <label htmlFor={`endTime_${index}`} className='block text-sm font-medium text-gray-700'>End Time</label>
                                            <input
                                                type='time'
                                                name={`endTime_${index}`}
                                                id={`endTime_${index}`}
                                                value={shift.endTime}
                                                onChange={(e) => {
                                                    const newShifts = [...addInstructorShiftsSunday];
                                                    newShifts[index].endTime = e.target.value;
                                                    setaddInstructorShiftsSunday(newShifts);
                                                }}
                                                className='px-3 py-1.5 border-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none'
                                            />
                                            <button
                                                disabled={addInstructorShiftsSunday?.length === 1}
                                                onClick={() => {
                                                    const newShifts = addInstructorShiftsSunday.filter((shft) => shft.id !== shift.id);
                                                    setaddInstructorShiftsSunday(newShifts);
                                                }}
                                                className='text-red-900 bg-red-200 hover:bg-red-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                            >
                                                <TrashIcon className='w-[18px] h-[18px]' />
                                            </button>
                                            <button

                                                onClick={() => {
                                                    handleAddRow()
                                                }}
                                                className='text-green-900 bg-green-200 hover:bg-green-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                            >
                                                <PlusIcon className='w-[18px] h-[18px]' />
                                            </button>
                                            <button
                                                onClick={() => {
                                                    resteHandlerRecord(shift)
                                                }}
                                                className='text-gray-900 bg-gray-200 hover:bg-gray-300 disabled:bg-gray-400 disabled:text-gray-800 p-1 rounded-full'
                                            >
                                                <ArrowUturnLeftIcon className='w-[18px] h-[18px]' />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div></Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>

                </div>
            </div>
            {/* <div className='mb-3 w-[90%] mx-auto text-left'>
                <label htmlFor='shifts' className='block text-sm font-medium text-gray-700'>
                    Working Shifts

                </label>
                <div className="flex flex-col items-center gap-2 mt-2">
                
                </div>
            </div> */}
            <div className='flex mx-14 my-5 gap-2'>
                <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-red-100 hover:bg-red-700 focus:outline-none "
                    onClick={e => {
                        e.preventDefault();
                        setShowDeleteInstructorModal(true);
                    }}
                >
                    Delete Instructor
                </button>
                <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-indigo-100 hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                    onClick={e => {
                        e.preventDefault();
                        saveInstructorUpdatedDetails('save');
                    }}
                >
                    Save Details
                </button>
                <Link
                    to={'/inHouseTeam'}
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-200 px-4 py-2 text-sm font-medium text-indigo-900 hover:bg-indigo-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                >
                    Cancel
                </Link>
            </div>
        </div>

    )
}

const mapStateToProps = (state) => ({
    allServices: state.allServices
})
export default connect(mapStateToProps, null)(InstructorsViewEditDetails)